<template>

    <!-- Main Content Area -->
    <div class="content">
      <br>
     <h3 class="px-4">My Experience</h3>
     <hr>
      
      <!-- Contact Form -->
    <div class="row">
      
   <h3 class="px-4">Sumary</h3>
   <hr>
 <div class="col-md-6">
  <h4 class="px-4">Imran Rahman</h4>
   <p class="px-4" style="text-align: justify;">
     I am a seasoned SAP ABAP consultant with 6+ years of experience delivering customized SAP solutions across industries. I specialize in SAP ABAP programming, SAP integration, module development, and optimization. My deep understanding of SAP environments allows me to tailor solutions that enhance business operations, reduce downtime, and increase efficiency.
   </p>
   <ul class="px-4" style="list-style: none;">
     <li> <font-awesome-icon icon="map-marker-alt" class="icon" /> Sector 05, Road 2B, Dhaka-1207 </li>
     <li><font-awesome-icon icon="phone"  class="icon" /> 01918329829 </li>
     <li> <font-awesome-icon icon="envelope" class="icon"  /> md.imran1200@gmail.com </li>
   </ul>
     <h4 class="px-4"><font-awesome-icon icon="graduation-cap"  class="icon"  /> Education</h4>
<hr>
     <h5 class="px-4">BSC IN CSE</h5>
<span class="px-4"><font-awesome-icon icon="calendar"  class="icon"  /> 2013 - 2016</span><br>
<span class="px-4"> <font-awesome-icon icon="university"  class="icon" /> <strong>Dhaka International University</strong></span>
<br>
<br>
 <h5 class="px-4">Diploma IN CSE</h5>
<span class="px-4"><font-awesome-icon icon="calendar"  class="icon" /> 2009 - 2012</span><br>
<span class="px-4"> <font-awesome-icon icon="university"  class="icon" /> <strong>Dhaka International University</strong></span>
<br>
<br>
<br>
<hr>
 <h4 class="px-4"><font-awesome-icon icon="graduation-cap"  class="icon"  /> 
 Professional Training/Courses</h4>
<hr>
     <h5 class="px-4">Web Application development      
using Php</h5>
     <h5 class="px-4">Topics:  </h5>
     <p class="px-4">
       HTML5, CSS3, PHP,  
CodeIgniter, Laravel, WordPress,  
MySQL, Ajax, JavaScript, Jqury 
     </p>
     <span class="px-4">
      <font-awesome-icon icon="university"  class="icon" /> <strong>Institute: </strong>
      IDB_BISEW IT SCHOLARSHIP </span><br>
<span class="px-4"><font-awesome-icon icon="calendar"  class="icon"  /> Duration: 6 Month  </span><br>

<br>



 </div>
 <div class="col-md-6">
    <h4 class="px-4">Professional Experience</h4>
   <h5 class="px-4">Assistant Manager</h5> 
<span class="px-4"> <font-awesome-icon icon="briefcase"  class="icon" /> <strong>Unique Group</strong></span><br>
<span class="px-4"><font-awesome-icon icon="calendar"  class="icon" /> 2019 - Present</span><br>
<span class="px-4"><font-awesome-icon icon="map-marker-alt" class="icon"  /> Banani - Dhaka-1207</span><br>
<span class="px-4"><strong>   Job Responsibility</strong></span><br>
<ul style="list-style: none;">
  <LI> <font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Working With Functional and core Team 
to Collect requirement </LI>
  <LI><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> 
  Team Meeting And discussion
   </LI>
  <LI><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Developed General Invoice. </LI>
  <LI><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Developed Food/Dress Bill for 
Education </LI>
  <LI><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Developed Customer Details ALV report </LI>
  <LI><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Developed Sales Invoice for Hotel 
Sector </LI>
  <LI><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Developed Customer Invoice Print out </LI>
  <LI><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Bapi Sales Oder Custom Upload 
Program </LI>
  <LI><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Complete Solution Land Management System for our Company  </LI>
  <LI><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Manage Hostng Configured, DNS </LI>
  <LI><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Setup, SSL Manage and Domain Control 
Setup and integration.</LI>


</ul>
<hr>
   <h5 class="px-4">Senior Web Application Developer</h5> 
<span class="px-4"> <font-awesome-icon icon="briefcase"  class="icon" /> <strong>Devszone</strong></span><br>
<span class="px-4"><font-awesome-icon icon="calendar"  class="icon" /> 2018 - 2019</span><br>
<span class="px-4"><font-awesome-icon icon="map-marker-alt"  class="icon" /> Mirpur - 02, Dhaka-1207</span><br>
<span class="px-4"><strong>   Job Responsibility</strong></span>
<ul style="list-style: none;">
<strong>Website Development:</strong> <br> 

<li><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Building websites or web </li>
<li><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Applications using programming languages such as HTML, CSS, 
JavaScript, and frameworks like 
React, Angular, or Vue.js.</li>
<li><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Backend Development: </li>
<li><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Implementing server-side logic, 
databases (SQL, NoSQL), and APIs 
(RESTful, Graph QL) to ensure the 
functionality of websites or 
applicatons. </li>
<li><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Responsive Design:  </li>
<li><font-awesome-icon :icon="['fas', 'check']" class="custom-icon" /> Creatng websites that work across 
various devices and screen sizes 
(mobile, tablet, desktop).</li>

</ul>
 </div>
    </div>
    </div>
  
</template>

<script>

export default {
    name: 'ContactPage',
  data() {
    return {
      isCollapsed: window.innerWidth < 768,
      form: {
        name: '',
        email: '',
        message: '',
      },
    };
  },

 
};
</script>

<style type="text/css">


  /* Contact Form Styling */
.contact-form {
  max-width: 600px;
  margin: 20px auto;
}

.contact-form h2 {
  margin-bottom: 20px;
}

.contact-form label {
  display: block;
  margin-top: 10px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-form button {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.contact-form button:hover {
  background-color: #0056b3;
}

/* Base icon styling */
.social-icons {
  display: flex;
  gap: 1rem;
}
.social-icons ul{
  list-style: none
}
.social-icons ul li a {
    display: block;
    padding: 16px 2px 5px 5px;
    text-decoration: none;
    color: #6b290a;
}
.icon {
  font-size: 1.5rem; /* Adjust icon size */
  transition: color 0.3s ease, transform 0.3s ease;
  /*padding: 0 2px 0 0px*/
}

/* Specific brand colors */
.facebook {
  color: #4267B2;
}
.instagram {
  color: #1DA1F2;
}
.linkedin {
  color: #0077B5;
}

/* Hover effects */
.icon:hover {
  color: #333; /* Change color on hover */
  transform: scale(1.2); /* Slightly enlarge on hover */
}
.icon{
  color: brown;
  font-size: 14px
}
</style>
